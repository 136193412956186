import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Alvearie/alvearie.github.io/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`By Richard Duggan `}{` `}{` `}{` | `}{` `}{` `}{` Published June 17, 2021`}</p>
    <p>{`The Alvearie `}<em parentName="p">{`Clinical Data Ingestion and Enrichment`}</em>{` health patterns capture how to both ingest and enrich HL7 and
FHIR data destined for a FHIR repository. Medical imaging study data, however, is stored in an alternate infomatics
system than HL7/FHIR clinical data. Commonly, a `}<em parentName="p">{`Picture Archiving and Communication System`}</em>{` (PACS) would efficiently store the
medical imaging data and provide access to that data using `}<em parentName="p">{`Digital Imaging and Communications in Medicine`}</em>{` (DICOM) standards.
The `}<a parentName="p" {...{
        "href": "https://github.com/Alvearie/health-patterns/tree/main/clinical-ingestion"
      }}>{`Alvearie Imaging Ingestion`}</a>{`
component provides a number of subcomponents for ingesting, managing, and routing medical imaging
studies. This post explores how to use a subset of those subcomponents to augment the `}<em parentName="p">{`Clinical Data Ingestion and
Enrichment`}</em>{` health patterns with medical imaging study information.`}</p>
    <h3>{`What is DICOM`}</h3>
    <p>{`DICOM is an extensive specification for both the medical imaging data and the communication of said data. For the
purposes of this post, we can take a narrow view of the DICOM information model as essentially a four-tiered
hierarchical structure. At the root is the `}<em parentName="p">{`patient`}</em>{`. A patient has a number of imaging `}<em parentName="p">{`studies`}</em>{`, each study
consists of a number of modality specific series, and a series is comprised of an ordered list of `}<em parentName="p">{`Service-Object
Pair Instances`}</em>{` (SOP Instances). A SOP Instance may be a single image, a number of image frames, a structured report,
an audio recording, etc.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "884px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "56.25%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABcElEQVQoz41T226CQBD1/z/I+OCTxpj44IsG0aQNpbaACAsLyE045YxRqTWpkywMM7NnbodBkiSI4xhKKVDn0TqGjhJUZYWiKMR2Op1wPp+RJhkOni93PM9DGIaIoghpmoIyyPMcZVlisVhgOBxiu92Kc7//6i4cEKoQq9UKjuPgeDx2YIdOdyXJZDKRQ3B+CyAva63FwEzUGdC2jQRUVSUJmLhtW7EVRS42Vv3+ZkHHWjoRQD4YeA3uy382JguOwS/foA/Wfz/qfRurcV0X5saUEVG/Vdi/2DTNH4BnSeq6lvGYponNxhCdtl8tv9rmozjfHqqivrfMJXC4tm1jPp93w06gQoXAD6Vi+hjDJQhtdIYwUFCRgu/78NwLddIsvW+ZG57NZhiNRrAsC0EQwP74lMDdbofpdIrlcikAF/oE0uZ4PBbaECPLsgsgFfJrvV7DMAyZCyvqb5Ix5OpV+lVffwzS6jbDZ/IKlR79/P4BtT5Pkt9sa+AAAAAASUVORK5CYII=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Screen Shot",
        "title": "Screen Shot",
        "src": "/static/449cee8153e0133ec4d2272547e31431/dd7d2/dicom_relationships.png",
        "srcSet": ["/static/449cee8153e0133ec4d2272547e31431/7fc1e/dicom_relationships.png 288w", "/static/449cee8153e0133ec4d2272547e31431/a5df1/dicom_relationships.png 576w", "/static/449cee8153e0133ec4d2272547e31431/dd7d2/dicom_relationships.png 884w"],
        "sizes": "(max-width: 884px) 100vw, 884px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <p>{`A DICOM study is unbounded. As an unbounded entity, each SOP Instance is an independently managed observation
containing all of the reference information to establish its location within the hierarchical DICOM model.
This is generally referred to as a `}<em parentName="p">{`Composite Object Instance`}</em>{`. As a consumer of DICOM, we never know when new SOP
Instances will be created or sent. For example, as a patient participates in a medical imaging study they move
between modalities. A modality establishes the patient orientation in 3D space, and captures a number of SOP
Instances (i.e. images) as an ordered series within the established orientation. Each modality sends the collected
SOP Instance to the PACS at different points in time. As the SOP Instances arrive at the PACS, they are organized
into the DICOM information model.`}</p>
    <h3>{`Capturing DICOM with FHIR resources`}</h3>
    <p>{`FHIR captures a patient’s medical imaging studies with `}<em parentName="p">{`ImagingStudy`}</em>{` resources. The `}<em parentName="p">{`ImagingStudy`}</em>{` resource provides a
metadata model that mirrors a subset of the previously described DICOM hierarchical (study, series, SOP instance)
information model. As metadata, it does not include the raw SOP Instance content. The `}<em parentName="p">{`ImagingStudy`}</em>{` resource uses the
`}<em parentName="p">{`Reference`}</em>{` resource to indicate where the SOP Instances are stored within the DICOM infomatics systems. Therefore,
having a FHIR repository is not sufficient for managing all of the information in the study. We need a separate repository
for storage, and services for retrieval, of SOP Instances that are referenced from the `}<em parentName="p">{`ImagingStudy`}</em>{`. A user-agent interacting
with patient FHIR `}<em parentName="p">{`ImagingStudy`}</em>{` resources is provided the hierarchical metadata of the study but must work with referenced
services to retrieve the SOP Instances from the repository.`}</p>
    <h3>{`Alvearie Imaging Ingestion Approach`}</h3>
    <p>{`Alvearie Imaging Ingestion is a real-time, event-driven subsystem for ingesting, managing, and routing DICOM SOP Instances.
Beyond that, it also provides the ability to conveniently interact with DICOM at the study level as opposed to the instance;
as is the case with the FHIR `}<em parentName="p">{`ImagingStudy`}</em>{`. The Alvearie Imaging Ingestion component achieves this by providing a number of
subcomponents, each as an independent Kubernetes Custom Resource for deployment and management. This subcomponent approach
allows for custom deployments of incremental medical imaging capabilities to enable a number of different use cases. The
subcomponents are designed to work together, providing DICOM standards-based request-response services on the edges with the
individual components interacting in an event-driven pattern within the core. It is simple to create highly elastic topologies
of resilient subcomponents that incrementally scale up and down with demand. Many more details are provided within the
`}<a parentName="p" {...{
        "href": "https://github.com/Alvearie/imaging-ingestion/"
      }}>{`Alvearie Imaging Ingestion`}</a>{` GitHub pages.`}</p>
    <h3>{`Simplified Ingestion Topology`}</h3>
    <p>{`In order to add DICOM data to the `}<em parentName="p">{`Clinical Data Ingestion and Enrichment`}</em>{` health patterns, we essentially have four requirements:`}</p>
    <ol>
      <li parentName="ol">{`We need a standards-based DICOM service for ingesting the SOP Instance data.`}</li>
      <li parentName="ol">{`We need a runtime to manage the SOP Instances and form an opinion of when a study is complete. This runtime should create a revisioned history of studies as they change over time.`}</li>
      <li parentName="ol">{`For each revision of an imaging study, we need to generate and publish FHIR `}<em parentName="li">{`ImagingStudy`}</em>{` resource to the clinical enrichment pipeline.`}</li>
      <li parentName="ol">{`We need a service for retrieving the previously ingested SOP Instances from references in the generated FHIR `}<em parentName="li">{`ImagingStudy`}</em>{`.`}</li>
    </ol>
    <p>{`A simplified, but limited, approach could be to provide the following topology and resulting data flow:`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1000px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "96.52777777777779%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAC+klEQVQ4y41UbU/TUBTezzTxV2j8SqKfNPoBFDVRIJEgiIIo4qKQiKIIGtjG3hx0L2xdu1FWurfupe29fTy9W8dIhniSu6bruc8953mecwO6rkPTNHihqGXs3h5D/iAKTu/McdBsWzCaDhzmihzvl3MXjPfesbEGtE2wfn4gn88jkUiIb78jcTx8uYzNnT3YNoFYbQR3C7izdIKYpCOTyiIjyTCbLZEfjiZx/9p1fF4NIppMYnt7GwH0w3VdDAdjXDyLehffkiYKikGAx6gaTfiZOVnB5n4MBykJpVIZhmEg4AEJMFocl0e3a6FQLOPMqMPRTqh6a2ReH7AHxYsF1GIHOCootFkF9w+jkDI53J2cxov5JTQm7gEVTfBm03L6/HHOe4CcXrxt7s4Wkk8e4fHieyyufKS2GWwSo1K34TFQb5roWrSx1YZjO72SYvuAnDuvcMAhobsjWojkWhh7XUIoVUH2MAdF1qBXaj0OSxrmbt7C+rMphJMppCUJAVVVEQwGB6Ael0JhxsR/5aqN7ykTcqkGJaeiWW8PDj4mUda2dvAzkkQ4EkE2m0UgFAphZmaGSO8KzhyHUVuW8BoGzgNa1GY4dohDKQcrm4bT6YwWJR6PY2FhgTa0vOIuALpDgEW1hInpOcy+WkZj8gGgn4ovjPIZdeYJ4ukRME1qR5Z9M4plWTYB90hvdTnkShcWMdDudMWEiCni594d9vCVouylTdyYVfArrkFKpMWknJ0asB3eB3IvAgrbsL5tInuQV99hPvgFb9fWqR0bVZMhmm+j1rRhdyyfATjuPyr0q3PjEajrn/BhaxcbX3/Asu1BYoM8uB/7QzN7hBaZnzUavT2uO7plXNKyL41SOsH481lMzb1B9ek4XJqqgdWGVfZLdv1KaXkqe2p7YXUYKgpVw84vDHE+rhBl+INNCtv90YrT9bUyvo9MQkHm6BiNmkngnPjl/w/oGdybY8YZKVpHpdiASRNi6DW6C9siz6v2SsBR9+KoEKbn7kjAv0FTpLl6Ha4gAAAAAElFTkSuQmCC')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Imaging ingestion flow",
        "title": "Imaging ingestion flow",
        "src": "/static/ddbaad50ba7c6ac7301bcc490ac637ea/ea415/imaging-ingestion-flow.png",
        "srcSet": ["/static/ddbaad50ba7c6ac7301bcc490ac637ea/7fc1e/imaging-ingestion-flow.png 288w", "/static/ddbaad50ba7c6ac7301bcc490ac637ea/a5df1/imaging-ingestion-flow.png 576w", "/static/ddbaad50ba7c6ac7301bcc490ac637ea/ea415/imaging-ingestion-flow.png 1000w"],
        "sizes": "(max-width: 1000px) 100vw, 1000px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <p>{`DICOM SOP Instances are ingested into the system through the `}<em parentName="p">{`DICOMweb`}</em>{` STOW-RS REST service (1) provided by the `}<strong parentName="p">{`DICOMweb Ingestion
Service`}</strong>{`. As SOP Instances are successfully stored into an S3 bucket (2), Cloud Events (3) are generated for the next subcomponent
in the pipeline; the DICOM Event Driven Ingestion. The `}<strong parentName="p">{`DICOM Event Driven Ingestion`}</strong>{` captures a manifest of all the SOP Instances
ingested within the pipeline, forming a hierarchical DICOM study model (4) as well as an opinion of whether a complete study has
been ingested. Once the DICOM Event Driven Ingestion determines a study has likely been completely ingested, it generates a Cloud
Event (5) for that first revision of the imaging study. The `}<strong parentName="p">{`DICOM Study Binding`}</strong>{` subscribes to the study-based Cloud Events and
generates a FHIR `}<em parentName="p">{`ImagingStudy`}</em>{` resource for the imaging study and publishes to (6) the FHIR Ingestion service. A user-agent can
retrieve the `}<em parentName="p">{`ImagingStudy`}</em>{` resource (7), containing the DICOM hierarchy of the imaging study. Each SOP Instance within the
`}<em parentName="p">{`ImagingStudy`}</em>{` is represented as an endpoint reference to the DICOMweb Ingestion service where it was originally ingested.
The SOP Instances can subsequently be retrieved using the DICOMweb WADO-RS (8) provided with that DICOMweb Ingestion Service.`}</p>
    <h3>{`Extended Ingestion Topology`}</h3>
    <p>{`In the real world, medical imaging centers are less likely to use DICOMweb and much more likely to use DICOM Message Service
Element (DIMSE) services for communication of DICOM data. Specifically, images are stored using a DIMSE C-Store composite
operation as opposed to the DICOMweb STOW-RS in the simplified topology. As such, we need to extend the previously defined
Simplified Approach to enable a bridge from DIMSE to the ingestion services.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "73.61111111111111%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACMElEQVQ4y32TbVPaQBSF+f9/o19s64fWtlZb2jrjVIqKZqZahYoEwQkEDIa8kmTz9G4gIyB1Zxb27s09e859qcRZBs4D6qxOAoRxSpqmqEyRi00cwWlNn4gSRaaKW/I8n285K20rVdiVLEsxLq7Z3tnlY92idj3Fd2X7URHYNnu8//SFgf3AmyOHxuWA8WCEkuBEI/kezGLKVUmShCieEYQhEy9hGmaFQzPRr/tBRF8AMmFgewpr8MjYdlAL/9HWa053P+N4PnfdLhWlFOtLU5/fZxy3XPbOp1i9Aa7johWnkibt1d/8ue3SEhXj8ZjhcEhlJR9Lu1z+DHqjiJ5pMfUiYSrPyE8Wx/KfPSNTKYNXAMVOm1ecNwyRO1gJuPrbZvvdLv6Pr0JxA+AmufNqtPhVO+am25/nVFgZZohpuSJtROQFxf1D44RRvUaQZgS+vwpYgtm2Tef+ftkjgDl7xpSL5ojH0YTZwnP0s061ekCne8e9xKwAlgUyDINv1eq896K4uC8f6/b67FcPCU6PNe2XJZdBlmXRbrcX3J7y6/iKTt+mYfwmaDXRJV8v5v9zqM/JjNjz8PxAWkXxtjbh8mZC6ProtpcssF7UjQz1GBXiu7ecbG3xamdfpEfIVDIcT2jedAjNDvliPJdb7RnDJXTyDdcdyeGH/e88Hh4UOczXVFU2Yz29qAsyk/FMEpn5A5O7lk0URgtfviL5ZcCS5WKn0meWOcGxp0xdDyX2JsB/aWuE6m5pYAEAAAAASUVORK5CYII=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Imaging extended topology",
        "title": "Imaging extended topology",
        "src": "/static/ce472dc6f9b721526427e8fd56774746/3cbba/imaging-extended-topology.png",
        "srcSet": ["/static/ce472dc6f9b721526427e8fd56774746/7fc1e/imaging-extended-topology.png 288w", "/static/ce472dc6f9b721526427e8fd56774746/a5df1/imaging-extended-topology.png 576w", "/static/ce472dc6f9b721526427e8fd56774746/3cbba/imaging-extended-topology.png 1152w", "/static/ce472dc6f9b721526427e8fd56774746/d534d/imaging-extended-topology.png 1498w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <p>{`The resulting topology is identical to the Simplified Approach with the exception that we introduced the `}<strong parentName="p">{`DIMSE Ingestion Service`}</strong>{`
for processing DIMSE C-STORE operations (1b). Also, we have added a `}<strong parentName="p">{`DIMSE Proxy`}</strong>{` that acts as a DIMSE Application Entity (AE) to
directly integrate with the existing DIMSE based communication systems within the medical imaging center. The DIMSE proxy and
DIMSE Ingestion subcomponents use a `}<a parentName="p" {...{
        "href": "https://nats.io/"
      }}>{`NATS`}</a>{` messaging service to bridge the DICOM to a multi-zone regional cloud deployment.`}</p>
    <h3>{`Conclusion`}</h3>
    <p>{`The `}<em parentName="p">{`Imaging Ingestion`}</em>{` component provides several different subcomponents for ingesting, managing, and routing DICOM SOP Instances
as well as the associated medical imaging studies. These specialized subcomponents can be composed into custom, fit for purpose,
use-case driven topologies. In this post, we have explored how using a subset of the provided components can be used to extend
the `}<em parentName="p">{`Clinical Ingestion and Enrichment`}</em>{` health patterns to include DICOM medical imaging data. Detailed documentation and deployment
details of each of the subcomponents is readily available within the `}<a parentName="p" {...{
        "href": "https://github.com/Alvearie/imaging-ingestion"
      }}>{`Alvearie Imaging Ingestion`}</a>{` GitHub pages.`}</p>
    <p />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      